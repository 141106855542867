import Vue from 'vue'
import './utils/base.js'

import App from './App.vue'
import router from './router'

import global from '@/utils/global.js'
Vue.prototype.$global = global

Vue.config.productionTip = false

import Mui from 'vue-awesome-mui'
// import 'vue-awesome-mui/mui/dist/css/mui.css'
Vue.use(Mui)

new Vue({
  data: {
    global
  },
  router,
  render: h => h(App)
}).$mount('#app')
