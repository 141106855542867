import Vue from 'vue'

import '@/assets/css/reset.scss'
import '@/assets/css/normalize.css'
import '@/assets/css/style.scss'

// Math.formatFloat (5.39 - 5.34, 2)
// f 计算表达式
// digit 小数位数
// 处理js小数精度丢失的bug
Math.formatFloat = function (f, digit) {
  var m = Math.pow(10, digit);
  return Math.round(f * m) / m;
}

import VueClipboard from 'vue-clipboard2'
 
Vue.use(VueClipboard)

import AudioPlay from '@/components/AudioPlay.vue';

import NoList from '@/components/NoList.vue'

Vue.component(AudioPlay.name, AudioPlay)
Vue.component(NoList.name, NoList)

//websocket链接
import ws from '@/utils/ws.js';

Vue.prototype.$ws = ws

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);