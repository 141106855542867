import Vue from 'vue'
import VueRouter from 'vue-router'
import MainLayout from '../components/MainLayout.vue'
import global from '@/utils/global.js'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'MainLayout',
    component: MainLayout,
    redirect: '/main',
    children: [
      { path: '/main', name: 'main', component: () => import('@/views/main/Main.vue'), meta: { index: 0, name: '首页' } },
      { path: '/task', name: 'task', component: () => import('@/views/task/Task.vue'), meta: { index: 0, name: '任务' } },
      { path: '/message', name: 'message', component: () => import('@/views/message/Message.vue'), meta: { index: 0, name: '消息' } },
      { path: '/mycenter', name: 'mycenter', component: () => import('@/views/mycenter/MyCenter.vue'), meta: { index: 0, name: '我的' } },
    ],
  },

  // 登录
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/Login.vue'),
    meta: { index: 0 }
  },
  // 注册
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/register/Register.vue'),
    meta: { index: 1 }
  },
  // 找回密码
  {
    path: '/lookfor',
    name: 'lookfor',
    component: () => import('../views/lookforpwd/LookForPwd.vue'),
    meta: { index: 1 }
  },




  // 垫付任务 未完成任务列表 type = 0 垫付任务
  {
    path: '/taskdianfuundone/:type',
    name: 'taskdianfuundone',
    component: () => import('../views/taskdianfu/taskundone/TaskUndone.vue'),
    meta: { index: 2 }
  },
  
  // 垫付任务 已完成的任务 type = 0 垫付任务
  {
    path: '/taskdianfualready/:type',
    name: 'taskdianfualready',
    component: () => import('../views/taskdianfu/taskalready/TaskAlready'),
    meta: { index: 2 }
  },

  //垫付任务 已撤销的任务 type = 0 垫付任务
  {
    path: '/taskdianfurevoked/:type',
    name: 'taskdianfurevoked',
    component: () => import('../views/taskdianfu/taskrevoked/TaskRevoked.vue'),
    meta: { index: 2 }
  },

  // 浏览任务 未完成任务列表 type = 1 浏览任务
  {
    path: '/taskliulanundone/:type',
    name: 'taskliulanundone',
    component: () => import('../views/taskliulan/taskundone/TaskUndone'),
    meta: { index: 2 }
  },

  //浏览任务 已完成的任务 type = 1 浏览任务
  {
    path: '/taskliulanalready/:type',
    name: 'taskliulanalready',
    component: () => import('../views/taskliulan/taskalready/TaskAlready.vue'),
    meta: { index: 2 }
  },
  //浏览任务 已撤销的任务 type = 1 浏览任务
  {
    path: '/taskliulanrevoked/:type',
    name: 'taskliulanrevoked',
    component: () => import('../views/taskliulan/taskrevoked/TaskRevoked.vue'),
    meta: { index: 2 }
  },




  // 预售任务 未完成任务列表 type = 2 预售任务
  {
    path: '/taskyusouundone/:type',
    name: 'taskyusouundone',
    component: () => import('../views/taskyusou/taskundone/TaskUndone.vue'),
    meta: { index: 2 }
  },
  
  //预售任务 已完成的任务 type = 2 预售任务
  {
    path: '/taskyusoualready/:type',
    name: 'taskyusoualready',
    component: () => import('../views/taskyusou/taskalready/TaskAlready.vue'),
    meta: { index: 2 }
  },
  //预售任务 已撤销的任务 type = 2 预售任务
  {
    path: '/taskyusourevoked/:type',
    name: 'taskyusourevoked',
    component: () => import('../views/taskyusou/taskrevoked/TaskRevoked.vue'),
    meta: { index: 2 }
  },

  

  // 账号安全
  {
    path: '/safecenter',
    name: 'safecenter',
    component: () => import('../views/safecenter/SafeCenter.vue'),
    meta: { index: 2 },
  },
  // 提现
  {
    path: '/cashout',
    name: 'cashout',
    component: () => import('../views/cashout/CashOut.vue'),
    meta: { index: 2 }
  },
  // 余额
  {
    path: '/balance',
    name: 'balance',
    component: () => import('../views/balance/Balance.vue'),
    meta: { index: 2 }
  },
  // 推广赚钱
  {
    path: '/mypromote',
    name: 'mypromote',
    component: () => import('../views/mypromote/MyPromote.vue'),
    meta: { index: 2 }
  },
  // 账号完成率
  {
    path: '/accountcomplete',
    name: 'accountcomplete',
    component: () => import('../views/accountcomplete/AccountComplete.vue'),
    meta: { index: 2 }
    
  },


  // 提现记录
  {
    path: '/cashouthistory',
    name: 'cashouthistory',
    component: () => import('../views/cashouthistory/CashOutHistory.vue'),
    meta: { index: 3 }
  },
  // 我的团队
  {
    path: '/myteam',
    name: 'myteam',
    component: () => import('../views/myteam/MyTeam.vue'),
    meta: { index: 3 }
  },
  // 我的推广订单
  {
    path: '/mypromoteorder',
    name: 'mypromoteorder',
    component: () => import('../views/mypromoteorder/MyPromoteOrder.vue'),
    meta: { index: 3 }
  },
  // 我的推广商家
  {
    path: '/mypromotestore',
    name: 'mypromotestore',
    component: () => import('../views/mypromotestore/MyPromoteStore.vue'),
    meta: { index: 3 }
  },
  // 佣金明细 本金明细 
  // type 0 任务本金明细 字符串
  // type 1 任务佣金明细
  // type 2 推广佣金明细
  {
    path: '/comdetails/:type',
    name: 'comdetails',
    component: () => import('../views/comdetails/ComDetails.vue'),
    meta: { index: 3 }
  },
  // 绑定身份证
  {
    path: '/changeidcard',
    name: 'changeidcard',
    component: () => import('../views/changeidcard/ChangeIdCard.vue'),
    meta: { index: 3 }
  },
  // 绑定银行卡
  {
    path: '/changebank',
    name: 'changebank',
    component: () => import('../views/changebank/ChangeBank.vue'),
    meta: { index: 3 }
  },
  // 绑定支付宝
  {
    path: '/changeallpay',
    name: 'changeallpay',
    component: () => import('../views/changeallpay/ChangeAllPay.vue'),
    meta: { index: 3 }
  },
  // 修改昵称
  {
    path: '/changename',
    name: 'changename',
    component: () => import('../views/changename/ChangeName.vue'),
    meta: { index: 3 }
  },
  // 修改登录密码
  {
    path: '/changepwd',
    name: 'changepwd',
    component: () => import('../views/changepwd/ChangePwd.vue'),
    meta: { index: 3 }
  },
  // 绑定账号列表 
  // type 0 淘宝 字符串
  // type 1 京东
  // type 2 拼多多
  // id 平台id
  {
    path: '/bindaccountlist/:type/:id',
    name: 'bindaccountlist',
    component: () => import('../views/bindaccountlist/BindAccountList.vue'),
    meta: { index: 3 }
  },


  
  // 新增编辑淘宝账号
  // type 0 淘宝 字符串
  // type 1 京东
  // type 0 拼多多
  // platid 平台id
  // id 账号id 0 新增 其他 编辑
  {
    path: '/bindaccount/:type/:platid/:id',
    name: 'bindaccount',
    component: () => import('../views/bindaccount/BindAccount.vue'),
    meta: { index: 4 }
  },
  // 二级团队
  {
    path: '/myteamsecond/:id',
    name: 'myteamsecond',
    component: () => import('../views/myteamsecond/MyTeamSecond.vue'),
    meta: { index: 4 }
  },

  {
    path: '/refresh',
    name: 'refresh',
    component: () => import('../views/refresh/Refresh.vue'),
    meta: { index: 4 }
  },
  
]

const router = new VueRouter({
  routes
})


router.beforeEach((to,from,next) => {
  // http://m.qisehuavip.com/#/main?app=1
  if(to.query.app == '1') global.isApp = 1
  const isLogin = global.token ? true : false;
  const toPath = ['/login', '/register', '/lookfor']
  if(toPath.includes(to.path)) {// toPath 相当于是路由白名单
    next();
  }else{
    //如果token存在，就正常跳转，如果不存在，则说明未登陆，则跳转到'login'
    isLogin? next() : next("/login");
  }
})

export default router
