import qs from 'qs'
import axios from 'axios';

import { Toast } from 'vant'

import global from '@/utils/global.js'


axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? '/api' : ''
axios.interceptors.request.use(config => {
  config.headers['X-Requested-With'] = 'XMLHttpRequest'
  config.headers['token'] = global.token
  // config.headers['lang'] = global.language
  if(config.headers['Content-Type'] === 'application/x-www-form-urlencoded;charset=UTF-8') {
    config.data = qs.stringify(config.data)
  }
  return config
},error => {
  return Promise.reject(error)
})


axios.interceptors.response.use(response => {
  global.loadingShow = false
  if (response.data.resCode === -1 ) { //没登陆 或者token过期
    global.logout()
  }else if(response.data.resCode === -2 ) {
    Toast(response.data.msg)
  }
  return response
},error => {
  global.loadingShow = false
  Toast('网络异常:'+error.response.status)
  return Promise.reject(error)
})

const handleResponse = (data) => {
  if(data.data.resCode < 0) return Object.assign(data, { success: false })
  return Object.assign(data, { success: true })
}

export const apiPostFormData = async (url, params = {}) => handleResponse(await axios.post(url, params,  { headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}} ))


export const apiPostData = async (url, params = {}) => handleResponse(await axios.post(url, params,  { headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}} ))

export const apiPostFile = async (url, params ={}) => handleResponse(await axios.post(url, params, { headers: {'Content-Type': 'multipart/form-data' }}))


// 登录
export const apiLogin = (params) => apiPostData('/home/main/login', params)

// 注册
export const apiRegister = (params) => apiPostData('/home/main/reg', params)

// 发送手机验证码
export const apiSendCode = (params) => apiPostData('/home/main/getMobileCode', params)

// 上传图片
export const apiUpLoadHeadImg = (params) => apiPostFile('/home/upload/img', params)

// 实名接口
export const apiUserRealName = (params) => apiPostData('/home/user/real', params)

// 新增/修改 平台账号
export const apiSaveAccount = (params) => apiPostData('/home/account/saveAccount', params)  

// 获取各个平台账号列表
export const apiGetAccountList = (params) => apiPostData('/home/account/getAccountList', params) 

// 平台对应默认账号
export const apiGetPlatformAccountList = (params) => apiPostData('/home/account/getPlatformAccountList', params) 

// 开始接单
export const apiStartTask = (params) => apiPostData('/home/account/startTask', params)

// 获取用户信息
export const apiGetUserInfo = (params) => apiPostData('/home/user/getInfo', params) 

// 修改用户头像 昵称
export const apiEditInfo = (params) => apiPostData('/home/user/editInfo', params)

// 获取任务配置
export const apiTaskConfig = (params) => apiPostData('/home/task/getConfig', params)

// 获取平台账号信息
export const apiGetAccountInfo = (params) => apiPostData('/home/account/getAccountInfo', params)

// 切换账号接单
export const apiSelectAccount = (params) => apiPostData('/home/account/selectAccount', params) 

// 停止接单
export const apiStopTask = () => apiPostData('/home/account/stopTask') 

// 获取配置
export const apiGetConfig = (params) =>  apiPostData('/home/main/getConfig', params)    

// 接收任务 
export const apiConfirmOrder = (params) => apiPostData('/home/task/confirmOrder', params) 

// 不接收任务 取消订单
export const apiCancelOrder = (params) => apiPostData('/home/task/orderCancel', params) 

// 获取任务数量
export const apiGetTotalOrder = (params) => apiPostData('/home/task/totalOrder', params)

// 获取任务列表 未完成 已完成 已取消
export const apiGetOrderList = (params) => apiPostData('/home/task/getOrderList', params) 

// 绑定支付宝/银行卡
export const apiBindBnk = (params) => apiPostData('/home/user/bindBank', params)

// 修改银行卡/支付宝
export const apiChangeBank = (params) => apiPostData('/home/user/editBank', params) 

// 获取银行卡支付宝列表
export const apiGetBankList = (params) => apiPostData('/home/user/getBankConfig', params)

// 申请提现
export const apiAddCash = (params) => apiPostData('/home/capital/addCash', params)

// 获取提现记录
export const apiGetCashList = (params) => apiPostData('/home/capital/getCashList', params) 

// 获取资金流水明细 0 任务本金明细 1任务佣金明细 2推广佣金明细
export const apiMoneyDetailList = (params) => apiPostData('/home/capital/moneyList', params)

// 修改密码
export const apiChangePassword = (params) => apiPostData('/home/user/changePassword', params) 

// 忘记密码找回密码
export const apiForgetPassword = (params) => apiPostData('/home/main/forgetPassword', params)

// 操作中 临时保存数据
export const apiSaveOrder = (params) => apiPostData('/home/task/saveOrder', params)

// 操作中 提交数据
export const apiConfirmStep = (params) => apiPostData('/home/task/confirmStep', params)  

// 获取消息列表
export const apiGetMsgList = (params) => apiPostData('/home/msg/getMsgList', params)

// 确认收货
export const apiConfirmReceipt = (params) => apiPostData('/home/task/confirmReceipt', params)  

// 获取推广页数据
export const apiGetSpreadTotal = (params) => apiPostData('/home/spread/getSpreadTotal', params) 

// 获取推广列表 我的团队 推广商家
export const apiGetSpreadList = (params) => apiPostData('/home/spread/getSpreadList', params)

// 首页获取今日收入
export const apiGetTotal = (params) => apiPostData('/home/user/getTotal', params)








