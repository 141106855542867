import router from "@/router"
// {resetRouter}
const global= {
  //判断是否外链
  isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
  },
  
  // 是否展示公告 0 不需要展示  1需要展示  localStorage.getItem('isShowNotice') ? localStorage.getItem('isShowNotice') : 
  isShowNotice: '1',

  // 是否是App
  isApp: 0,

  // 设置展示公告
  setShowNotice(val) {
    // window.localStorage.setItem('isShowNotice', val)
    global.isShowNotice = val
  },
  config: {
    SYSTEM: {
      title: '',
      version: ''
    },
    TASK: {
      task_time: ''
    },
    BUY_KEFU: {
      qq: '',
      wechat: ''
    },
    SPREAD_BUY: {
      spread_20_1: "",
      spread_20_2: "",
      spread_50_1: "",
      spread_50_2: "",
      spread_150_1: "",
      spread_150_2: "",
      spread_1: "",
      spread_2: "",
      spread_first: "",
    },
    BULLETIN: {
      bullent: ''
    },
    CASH: {
      bj: "",
      date: "",
      remark: "",
      switch: "",
      yj: "",
      yj_fee: ""
    }
  },


  token: localStorage.getItem('appToken'),

  md5: localStorage.getItem('md5') ? localStorage.getItem('md5') : '', //判断公告是否更新

  isChangeBullent: false, //判断公告是否更新

  userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {}, //用户信息

  ApiList: [],
  
  MenuList: [],

  allSliceFlag: false, //是否静音

  autoPlayFlag: false, //是否自动播放过一次

  loadingShow: false, //加载动画
  
  // 设置MD5值
  setMd5(md5) {
    if(md5 === global.md5) {
      global.isChangeBullent = false
    } else {
      global.isChangeBullent = true
      window.localStorage.setItem('md5', md5)
      global.md5 = md5
    }
  },


  // 设置token
  setToken(token) {
    window.localStorage.setItem('appToken', token)
    global.token = token
  },
  
  // 设置用户信息
  setUserInfo(obj) {
    window.localStorage.setItem('userInfo', JSON.stringify(obj))
    global.userInfo = obj
  },
  // unreadMsgNum: 0,
  
  // 订单提示声音开关
  orderMusic: localStorage.getItem('orderMusic') ? localStorage.getItem('orderMusic') : '1', //用户信息

  // 设置订单声音提示
  setOrderMusic(val) {
    window.localStorage.setItem('orderMusic', val)
    global.orderMusic = val
  },

  // 没有被打开的平台id列表
  checkplatlist: localStorage.getItem('checkplatlist') ? JSON.parse(localStorage.getItem('checkplatlist')) : [],
  
  // 设置 checkplatlist
  setCheckplatlist(val) {
    global.checkplatlist.push(val)
    // 去重
    global.checkplatlist = global.checkplatlist.filter((item,index) => {
      return global.checkplatlist.indexOf(item) === index
    })
    window.localStorage.setItem('checkplatlist', JSON.stringify(global.checkplatlist) )
  },

   // 删除或者清空 checkplatlist
   setRemoveplatlist(id) {
    if(id) {
      global.checkplatlist.splice(global.checkplatlist.indexOf(id), 1) 
      window.localStorage.setItem('checkplatlist', JSON.stringify(global.checkplatlist))
      return
    } 
    window.localStorage.setItem('checkplatlist', '[]')
    global.checkplatlist = []
  },

  //退出登录
  logout() {
      localStorage.removeItem('appToken')
      localStorage.removeItem('userInfo')
      localStorage.removeItem('orderMusic')
      localStorage.removeItem('isShowNotice')
      global.token = null
      global.MenuList = []
      global.ApiList = []
      router.push('/login')
    },
}

export default global