<template>
  <div class="c_audio_play" v-show="false">
    <!-- Chrome和Safari 浏览器基于安全的策略，已经停止自动播放  必须要你进行和浏览器进行事件互动,人为的点击，触摸等操作才能播放 -->
    <audio preload="preload" controls="controls" ref="receiveMsgMusic"> 
      <source :src="newsMusicSrc" type="audio/mpeg"/>
    </audio>
  </div>
</template>

<script>

  export default {
    name: 'AudioPlay',
    props: {
      allSliceFlag: {
        type: Boolean,
        default() {
          return false
        }
      }
    },
    data() {
      return {
        newsMusicSrc: require('../assets/audio/news-second.mp3'),
        isiOS: false,
      }
    },
    created() {
      var u = navigator.userAgent;
      this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    },
    methods: {
      // 静音全部播放一次
      sliceAllPlay() {
        console.log('play')
        this.$global.autoPlayFlag = true
        let receiveMusicDom = this.$refs.receiveMsgMusic
        receiveMusicDom.muted = 'muted' //静音
        receiveMusicDom.pause()
        receiveMusicDom.currentTime = 0
        receiveMusicDom.play()
      },


      // 收到消息时播放
      receivePlay() {
        let receiveMusicDom = this.$refs.receiveMsgMusic
        receiveMusicDom.muted = '' //开声音
        if(this.allSliceFlag) receiveMusicDom.muted = 'muted'
        receiveMusicDom.pause()
        receiveMusicDom.currentTime = 0
        receiveMusicDom.play().catch(() => {})
      },
    }
  }
</script>

<style lang="scss">
.c_audio_play {
  position: absolute;
  top: 0;
}
</style>