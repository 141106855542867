<template>
  <div id="app">
    <AudioPlay ref="AudioPlay" :allSliceFlag="$global.allSliceFlag"/>
    <transition :name="transitionName">
      <router-view/>
    </transition>
    
    <receive-order ref="ReceiveOrder"/>

    <Loading color="#F9436B" v-show="$global.loadingShow"/>
  </div>
</template>
<script>
import { addEventListener } from '@/utils/index.js'
import ReceiveOrder from './components/ReceiveOrder.vue'
import Loading from './components/Loading.vue'
import { apiGetConfig } from '@/utils/api.js'
export default {
  components: { ReceiveOrder, Loading },
  data() {
    return {
      transitionName: '',
      systemCodeArr: ['SYSTEM', 'TASK', 'BUY_KEFU', 'SPREAD_BUY', 'BULLETIN', 'CASH']
    }
  },
  created() {
    // 订阅接单消息
    this.$ws.addFn('taskOrder', (msg) => {
      this.receiveOrder(msg)
    })
    // 静音全部播放一次
    this.triggerAllSlicePlay()
    // 获取配置
    this.apiGetConfigHandel()
  },
  methods: {
      // 获取配置
      async apiGetConfigHandel() {
        const { success, data } = await apiGetConfig({ code_arr: this.systemCodeArr })
        if(!success) return
        this.$global.config = data.data
        document.title = data.data.SYSTEM.title
        console.log('config', this.$global.config)
      },

      // 收到订单
      receiveOrder(msg) { 
        if(this.$global.orderMusic == 1) this.$refs.AudioPlay.receivePlay()
        this.$refs.ReceiveOrder.onShow(msg)
      },


      // 监听页面根元素的点击事件静音全部播放一次
      triggerAllSlicePlay() {
        let obj = document.documentElement|| window.pageYOffset || document.body
        addEventListener(obj, 'click', this.autoPlayHandler);
      },

      // 处理静音全部播放一次
      autoPlayHandler() {
        // 获取根元素
        let obj = document.documentElement || window.pageYOffset || document.body
          if(this.$global.autoPlayFlag) { //是否静音全部播放一次 是的话移除body的点击事件
            if (obj.removeEventListener) {
                obj.removeEventListener("click",this.autoPlayHandler,false);
            } else {
                // ie8及以下，只支持事件冒泡
                obj.detachEvent("onclick",this.autoPlayHandler);
            }
          }else { //还没有全部静音播放一次 需要进行播放
            this.$refs.AudioPlay.sliceAllPlay()
          }
      }
  },
  watch: {
    $route(to, from) {
      if(from.fullPath === '/') return
      if(to.meta.index > from.meta.index){  //前进
          // console.log('前进')
          this.transitionName = 'slide-left';
        }else { //后退
          // console.log('后退')
          this.transitionName = 'slide-right';
        }
    } 
  }
}
</script>
<style lang="scss">
#app {
  position: relative;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: constant(safe-area-inset-bottom); //苹果底部安全距离
  padding-bottom: env(safe-area-inset-bottom); //苹果底部安全距离
}

body,html,#app {
  width: 100%;
  height: 100%;
}

.slide-right-enter-active,
.slide-right-leave-active {
    will-change: transform;
    transition: all .4s;
    position: absolute;
    width:100%;
    left:0;
}

.slide-left-enter-active,
.slide-left-leave-active {
    will-change: transform;
    transition: all .4s;
    position: absolute;
    width:100%;
    left:0;
}

// 前进
.slide-left-enter {
    transform: translateX(100%);
}
.slide-left-leave-active {
    transform: translateX(-60px);
    opacity: 0;
}

// 后退
.slide-right-enter {
    transform: translateX(-140px);
}
.slide-right-leave-active {
    transform: translateX(0);
    opacity: 0;
}

</style>
