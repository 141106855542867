<template>
  <div class="c_receive_order">
    <van-popup v-model="show" class="c-receive-order-pop g-flex-column g-flex-align-center">
      <div class="c-receive-close" @click="onClose">
        <i class="iconfont icon-guanbi"></i>
      </div>

      <p class="c-receive-title">有新的单子拉~</p>

      <div class="c-receive-content g-flex-align-center">
        <img class="c-receive-content-left" :src="form.img[0]" alt="">
        <div class="c-receive-content-right">
          <div class="c-receive-content-right-item g-flex-align-center">
            <span class="c-receive-content-right-item-label">任务类型</span>
            <p class="c-receive-content-right-item-value">{{form.platform_play_title}}</p>
          </div>
          <div class="c-receive-content-right-item g-flex-align-center">
            <span class="c-receive-content-right-item-label">任务佣金</span>
            <p class="c-receive-content-right-item-value c-receive-content-right-item-price-value">{{form.buy_price}}元</p>
          </div>
          <div class="c-receive-content-right-item g-flex-align-center">
            <span class="c-receive-content-right-item-label">付款总价</span>
            <p class="c-receive-content-right-item-value">{{form.ben_price}}元</p>
          </div>
          <div class="c-receive-content-right-item g-flex-align-center">
            <span class="c-receive-content-right-item-label">拍下件数</span>
            <p class="c-receive-content-right-item-value">{{form.buy_num}}件</p>
          </div>
        </div>
      </div>
      
      <div class="c-receive-tips" v-show="form.explain">
        <p class="c-receive-tips-title">
          <i class="iconfont icon-dengpao"></i>
          商家任务要求:
        </p>
        <div class="c-receive-tips-text">{{form.explain}}</div>
      </div>

      <div class="c-receive-btn-list g-flex-align-center">
        <!-- <div class="c-receive-btn-cancel">
          <operation-button :buttonObj="buttonObjCancel" :isPadding="false" @clickItem="emitClick"/>
        </div> -->
        <div class="c-receive-btn-receive">
          <operation-button :buttonObj="buttonObj" :isPadding="false" @clickItem="emitClick"/>
        </div>
      </div>


    </van-popup>
  </div>
</template>

<script>
  import OperationButton from './OperationButton.vue'
  import { apiConfirmOrder, apiCancelOrder } from '@/utils/api.js'
  export default {
    components: { OperationButton },
    data() {
      return {
        show: false,

        buttonObj: {
          claSS: 'c-btn-default',
          title: '开始任务',
          event: 'apiConfirmTaskHandel'
        },
         buttonObjCancel: {
          claSS: 'c-btn-default c-btn-cancel',
          title: '放弃',
          event: 'apiHandel'
        },

        form: {
          id: '',
          buy_num: '0',
          buy_price: '0',
          ben_price: '0',
          img: [],
          platform_play_title: '',
          title: '',
          explain: '',
          type: '0'
        }
      }
    },
    methods: {
      // 开
      onShow(obj) {
        this.form = obj
        this.show = true
      },

      // 关
      onClose() {
        this.show = false
      },
      
      
      emitClick(event) {
        if(!event) return
        this[event]()
      },

      // 接取任务
      async apiConfirmTaskHandel() {
        const { success, data } = await apiConfirmOrder({ id: this.form.id })
        if(!success) return
        this.$toast(data.msg)
        this.onClose()
        if(this.form.type == 0) return this.$router.push({ name: 'taskdianfuundone', params: { type: this.form.type.toString() }}).catch(() => { this.$router.push({ name: 'refresh' })})
        if(this.form.type == 1) return this.$router.push({ name: 'taskliulanundone', params: { type: this.form.type.toString() }}).catch(() => { this.$router.push({ name: 'refresh' })})
        if(this.form.type == 2) return this.$router.push({ name: 'taskyusouundone', params: { type: this.form.type.toString() }}).catch(() => { this.$router.push({ name: 'refresh' })})
     },

      // 取消任务
      async apiHandel() {
        const { success, data } = await apiCancelOrder({ id: this.form.id })
        if(!success) return
        this.$toast(data.msg)
        this.onClose()
      }
    }
  }
</script>

<style lang="scss">
.c_receive_order {
  .c-receive-order-pop {
    min-width: 330px;
    max-width: 360px;
    padding: 20px 0 20px 0;
    border-radius: 12px;
    .c-receive-close {
      position: absolute;
      right: 10px;
      top: 12px;
      padding: 5px;
      .icon-guanbi {
        font-size: 18px;
        color: #B0B0B0;
      }
    }


    .c-receive-title {
      color: $main_color;
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 14px;
      letter-spacing: 2px;
    }

    .c-receive-content {
      padding: 0 20px 20px 20px;
      align-self: stretch;
      .c-receive-content-left {
        width: 110px;
        height: 110px;
        object-fit: cover;
        border-radius: 12px;
        overflow: hidden;
      }
      .c-receive-content-right {
        flex: 1;
        padding-left: 10px;
        .c-receive-content-right-item {
          padding-top: 10px;
          &:nth-of-type(1) {
            padding-top: 0;
          }
          .c-receive-content-right-item-label {
            color: #A7A7A7;
            font-size: 15px;
          }
          .c-receive-content-right-item-value {
            flex: 1;
            padding-left: 8px;
            text-align: left;
            color: #040404;
            font-size: 15px;
            @include textManyOverflow();
            &.c-receive-content-right-item-price-value {
              font-size: 20px;
              font-weight: bold;
              color: $main_color;
            }
          }
        }
      }
    }

    .c-receive-tips {
      align-self: stretch;
      padding: 0 20px 20px;
      .c-receive-tips-title {
        color: $main_color;
        font-size: 14px;
        .iconfont {
          font-size: 18px;
        }
      }
      .c-receive-tips-text {
        padding-top: 8px;
        font-size: 14px;
        color: $black;
        line-height: 22px;
        text-align: justify;
        word-break: break-all;
      }
    }
     
    .c-receive-btn-list {
      align-self: stretch;

      .c-receive-btn-cancel {
        padding: 0 20px;
        flex: 2;
      }
      .c-receive-btn-receive {
        flex: 3;
        padding: 0 20px;
      }
    } 

  }
}
</style>